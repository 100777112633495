.make-caret(@base, @left, @right) {
  // dashed: fix caret size for Mozilla Firefox
  border-@{base}: @caret-width-base dashed;

  border-@{left}: @caret-width-base solid transparent;
  border-@{right}: @caret-width-base solid transparent;
}

.make-nested-list(@offset, @i, @n) when (@i < @n) {
  > .dropdown-menu > li {
    &.dropdown-header,
    > a {
      padding-left: @offset + (10 * @i);
    }

    .make-nested-list(@offset, @i + 1, @n);
  }
}
