// :after: friends with IE8. Use ::after in future.

@import "../node_modules/bootstrap/less/variables.less";
@import "mixins.less";

// Variables
@caret-margin: -@caret-width-base * 2 - 2;

//
// Sub-Menus
// --------------------------------------------------

.dropdown-submenu > a:after {
  content: "";
}

@media (min-width: @grid-float-breakpoint) {
  .dropdown-submenu {
    position: relative;

    .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
      border-top-left-radius: 0;

      // Strictly before .dropdown-menu-right
      .dropup &,
      .navbar-fixed-bottom & {
        top: auto;
        bottom: 0;
        margin-top: 0;
        margin-bottom: -6px;
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: 0;
      }

      .dropdown-menu-right &,
      .navbar-right & {
        left: auto;
        right: 100%;

        border-top-left-radius: @border-radius-base;
        border-top-right-radius: 0;

        .dropup &,
        .navbar-fixed-bottom & {
          border-radius: @border-radius-base @border-radius-base 0;
        }
      }
    }

    > a:after {
      float: right;
      margin-top: @line-height-computed / 2 - @caret-width-base;
      margin-right: @caret-margin;

      .make-caret(left, top, bottom);

      .dropdown-menu-right &,
      .navbar-right & {
        float: left;
        border-left: none;
        margin-left: @caret-margin;
        margin-right: 0;

        .make-caret(right, top, bottom);
      }
    }
  }
}

@media (max-width: @grid-float-breakpoint-max) {
  .dropdown-submenu {
    .dropdown-menu {
      position: static;
      margin-top: 0;
      border: 0;
      box-shadow: none;
    }

    > a:after {
      margin-left: 6px;
      display: inline-block;
      vertical-align: middle;

      .make-caret(top, left, right);
    }
  }

  .dropdown-menu > .dropdown-submenu {
    .dropdown > &,
    .dropup > &,
    .btn-group > & {
      .make-nested-list(30px, 0, 4);
    }

    .navbar-nav > .dropdown > & {
      .make-nested-list(35px, 0, 4);
    }
  }
}
